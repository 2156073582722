import { pluck } from 'ramda';
import React, { ReactNode, memo, useMemo } from 'react';
import { countriesWithForcedCuratedListSearch } from '@bridebook/toolbox/src/i18n/features';
import { Box } from '@bridebook/ui';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { FiltersSectionOrder } from 'app-shared/lib/search/utils/section-order';
import NumberOfBedroomsFilter from 'components/search/filters/number-of-bedrooms-filter/number-of-bedrooms';
import SearchIncreaseRadiusFilter from 'components/search/filters/search-increase-radius/search-increase-radius-filter';
import WeddingEstimatePriceFilter from 'components/search/filters/wedding-estimate-price-filter/wedding-estimate-price-filter';
import { getPricingFilterOptions } from 'lib/search/get-pricing-filter-options';
import { useSearchParams } from 'lib/search/hooks/query/use-search';
import { useIsGlobalSearch } from 'lib/search/hooks/use-is-global-search';
import { useShowRadiusFilter } from 'lib/search/hooks/use-show-radius-filter';
import { useShowWeddingEstimatePriceFilter } from 'lib/search/hooks/use-show-wedding-estimate-price-filter';
import { getFiltersSections, getSearchFields, getSearchRequestSlug } from 'lib/search/selectors';
import { getCountryFilters, getFilters, getSectionFilters } from 'lib/search/utils';
import getFiltersPerSupplierCategory from 'lib/supplier/get-filters-per-supplier-category';
import { useSelector } from 'lib/utils';
import GuestsFilter from '../guests-filter/guests-filter';
import FilterSection from './filter-section';
import componentStyles from './filters-container.style';

const FiltersContainer = () => {
  const searchParams = useSearchParams();
  const market = useMarket();
  const countryCode = searchParams?.market.country;
  const searchMarket = searchParams?.market;
  const isCoreMarket = !!searchMarket?.flags.monetized;
  const fields = useSelector(getSearchFields);
  const filtersSections = useSelector(getFiltersSections);
  const slug = useSelector(getSearchRequestSlug);
  const category = slug ? slug : fields.type;
  const isVenue = category === 'venue';
  const filtersPerSupplierCategory = getFiltersPerSupplierCategory(category);
  const sectionFilters = getSectionFilters(category, filtersSections, filtersPerSupplierCategory);
  const searchFilters = getFilters(category, filtersSections, filtersPerSupplierCategory);
  const showRadiusFilter = useShowRadiusFilter();
  const isGlobalSearch = useIsGlobalSearch();
  const showWeddingEstimatePriceFilter = useShowWeddingEstimatePriceFilter();

  const orderedFilters = useMemo<FiltersSectionOrder | undefined>(() => {
    if (countryCode) {
      const filters = getCountryFilters(countryCode);
      // for quick pricing filter we're using pricing categories in price section
      if (isCoreMarket) {
        filters.priceSection = getPricingFilterOptions().map((pricingOption) => pricingOption.key);
      }

      return Object.entries(filters).reduce(
        (acc: FiltersSectionOrder, [section, fields]: [string, string[]]) => ({
          ...acc,
          ...(fields.length ? { [section]: fields } : {}),
        }),
        {} as FiltersSectionOrder,
      );
    }
  }, [countryCode, isCoreMarket]);
  /**
   * We show curated list filter for countries with forced curated list search and for global venue search.
   */
  const shouldShowIsCuratedFilter =
    countriesWithForcedCuratedListSearch.includes(market.country) || (isGlobalSearch && isVenue);

  const customSections: Record<string, ReactNode> = useMemo(
    () => ({
      accommodationRoomsSection: <NumberOfBedroomsFilter />,
      guestsSection: <GuestsFilter />,
      distanceSection: showRadiusFilter ? <SearchIncreaseRadiusFilter disableHeading /> : null,
      priceSection: showWeddingEstimatePriceFilter ? <WeddingEstimatePriceFilter /> : undefined,
    }),
    [orderedFilters, showRadiusFilter, showWeddingEstimatePriceFilter],
  );

  if (!countryCode || !orderedFilters) return null;

  const styles = componentStyles({ isVenue });

  return (
    <Box style={styles.wrapper}>
      {showRadiusFilter && !isVenue && (
        <Box style={styles.sectionWrap}>
          <SearchIncreaseRadiusFilter />
        </Box>
      )}
      {isVenue
        ? Object.keys(orderedFilters).map((sectionName, index) => (
            <Box key={`fsection-${sectionName}`} style={styles.sectionWrap}>
              <FilterSection
                {...{ index, sectionName, searchFilters, category }}
                fields={orderedFilters[sectionName as keyof typeof orderedFilters]}
                customSectionContent={customSections[sectionName]}
              />
            </Box>
          ))
        : sectionFilters.map((section, index) => {
            const sectionName = section && section[0] ? section[0].section : null;
            return sectionName && isCoreMarket ? (
              <Box key={`fsection-${sectionName}`} style={styles.sectionWrap}>
                <FilterSection
                  {...{ index, sectionName, searchFilters, category }}
                  fields={pluck('field', section)}
                />
              </Box>
            ) : null;
          })}
      {shouldShowIsCuratedFilter && (
        <Box style={styles.sectionWrap}>
          <FilterSection
            category="venue"
            sectionName="destinationWeddingsSection"
            searchFilters={[
              {
                field: 'isCurated',
                section: 'destinationWeddingsSection',
              },
            ]}
            fields={['isCurated']}
          />
        </Box>
      )}
    </Box>
  );
};

export default memo(FiltersContainer);
